<template>
    <div class="contacts-table">
        <vx-card>
            <vue-bootstrap4-table
                    :rows="data"
                    :columns="columns"
                    :config="config"
                    :actions="actions"
                    @on-change-query="onChangeQuery"
                    :total-rows="total_rows">
                <template slot="row_actions" slot-scope="props">
                    <div class="column-actions-group">
                        <vx-tooltip text="Bearbeiten" position="left">
                            <vs-button color="warning" type="filled" icon="edit" size="small"
                                       :to="'/contacts/'+props.row.id"></vs-button>
                        </vx-tooltip>
                        <vx-tooltip text="Löschen" position="left">
                            <vs-button color="primary"
                                       type="filled"
                                       icon="delete_forever"
                                       size="small"
                                       @click="openDeleteConfirm(props.row.id)"></vs-button>
                        </vx-tooltip>
                    </div>
                </template>

                <template slot="is_newsletter_recipient" slot-scope="props">
                    <div class="column-actions-group">
                        <vs-switch color="success" v-model="props.row.is_newsletter_recipient"
                                   icon-pack="feather" vs-icon="icon-check" @change="switchNewsletterRecipient(props.row)"/>

                    </div>
                </template>

                <template slot="letter_salutation" slot-scope="props">
                    <div class="column-actions-group">
                        <vs-input v-model="props.row.letter_salutation"
                                   />
                        <vs-button color="success" type="border" icon="save"  class="ml-2"
                                    @click="updateLetterSalutation(props.row)">

                        </vs-button>

                    </div>
                </template>
                <template slot="empty-results">
                    Keine Kontakte gefunden.
                </template>
            </vue-bootstrap4-table>
        </vx-card>
    </div>
</template>

<script>
    import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'

    import ApiService from "../../../api";

    import moment from 'moment';
    import QueryHelper from "../../../mixins/helper/query.helper";
    import * as qs from "qs";

    export default {
        components: {
            VueBootstrap4Table
        },
        data() {
            return {
                data: [],
                actions: [
                    {
                        btn_text: "Hinzufügen",
                        router_link: '/contacts/add',
                        color: 'success'
                    }
                ],
                columns: [
                    {
                        label: "Kontakt-Nr.",
                        name: "id",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        column_classes: 'customer-number-column'
                    },
                    {
                        label: "Nachname",
                        name: "last_name",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left'
                    }, {
                        label: "Vorname",
                        name: "first_name",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left'
                    },
                    {
                        label: "Tel",
                        name: "phone",
                        sort: true,
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                    },
                    {
                        label: "Mobil",
                        name: "mobile",
                        sort: true,
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                    },
                    {
                        label: "Briefanrede",
                        slot_name: "letter_salutation",
                        name: "letter_salutation",
                        sort: true,
                        filter: {
                            type: "simple",
                            placeholder: "Suchen...."
                        },
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                    },
                    {
                        label: "E-Mail",
                        name: "email",
                        sort: true,
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                    },

                    {
                        label: "Newsletter Empfänger",
                        slot_name: "is_newsletter_recipient",
                        name: "is_newsletter_recipient",
                        sort: true,
                        filter: {
                            type: "simple",
                            placeholder: "1/0"
                        },
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                    },
                    {
                        label: "Actions",
                        slot_name: "row_actions",
                        sort: false,
                        row_text_alignment: 'text-right',
                        column_text_alignment: 'text-right',
                        column_classes: 'actions-column'
                    }],
                config: {
                    checkbox_rows: false,
                    show_refresh_button: false,
                    show_reset_button: false,
                    highlight_row_hover_color: '#f8f8f8',
                    server_mode: true
                },
                queryParams: {
                    sort: [],
                    filters: [],
                    global_search: "",
                    per_page: 10,
                    page: 1,
                },
                total_rows: 0,
                selectedForDialog: null
            }
        },
        computed: {
            requestParams() {
                let params = this.queryParams;
                return QueryHelper.parseRequestParams(params);
            }
        },
        methods: {
            dateFilterValidator(column, value) {
            },
            fetchData() {
                this.$vs.loading()

                ApiService.get('contacts', {
                    params: this.requestParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                }).then(response => {
                    this.data = response.data.result;
                    this.total_rows = response.data.total;
                    this.$vs.loading.close()
                })
            },
            openDeleteConfirm(id) {
                this.selectedForDialog = id;

                this.$vs.dialog({
                    type: 'confirm',
                    color: 'primary',
                    title: 'Bestätigen',
                    text: 'Sind Sie sich sicher dass dieser Kontakt gelöscht werden soll?',
                    accept: this.acceptDelete,
                    acceptText: 'Löschen',
                    cancelText: 'Abbrechen'
                })
            },
            acceptDelete() {
                ApiService.delete('contacts/' + this.selectedForDialog).then((response) => {

                    this.fetchData();

                    if(response.data.status === 'success') {
                        return this.$vs.notify({
                            title: 'Erfolgreich',
                            text: 'Der Kunde wurde erfolgreich gelöscht',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        });
                    }

                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Kunde konnte nicht gelöscht werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                }).catch((error) => {
                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Kunde konnte nicht gelöscht werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
            },
            formatDate(value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            },
            onChangeQuery(queryParams) {
                this.queryParams = queryParams;
                this.fetchData();
            },
            switchNewsletterRecipient(row){

                    let payload = { is_newsletter_recipient: row.is_newsletter_recipient};

                    ApiService.put('contacts/' + row.id, payload).then((response) => {



                        if(response.data.status === 'success') {
                            this.fetchData();
                            return this.$vs.notify({
                                title: 'Erfolgreich',
                                text: 'Der Kontakt wurde erfolgreich aktualisiert',
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'success'
                            });
                        }

                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: 'Der Kontakt konnte nicht aktualisiert werden.',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }).catch((error) => {
                        this.fetchData();
                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: 'Der Kontakt konnte nicht aktualisiert werden.',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    })

            },
            updateLetterSalutation(row){

                    let payload = { letter_salutation: row.letter_salutation};

                    ApiService.put('contacts/' + row.id, payload).then((response) => {



                        if(response.data.status === 'success') {
                            this.fetchData();
                            return this.$vs.notify({
                                title: 'Erfolgreich',
                                text: 'Der Kontakt wurde erfolgreich aktualisiert',
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'success'
                            });
                        }

                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: 'Der Kontakt konnte nicht aktualisiert werden.',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }).catch((error) => {
                        this.fetchData();
                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: 'Der Kontakt konnte nicht aktualisiert werden.',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    })

            }
        },
    }

</script>

<style lang="scss">
    .contacts-table {
        .vbt-table-wrapper {
            .actions-column {
                width: 100px;
            }
            .customer-number-column {
                width: 190px;
            }
        }
    }
</style>
